.social-top-2 h1 {
    color: #2A2735;

    /* Infiniscape/H2 */
    font-family: Poppins;
    font-size: 38px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%; /* 41.8px */
}

.social-audit-color-panel h4 {
    color: #09080D;
    /* Infiniscape/H5 */
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%; /* 19.8px */
    }

.social-top-2 {
    margin-top: 80px;
}

.social-audit-header-text {
    color: var(--Infiniscape-MIdnight-Breeze, #2A2735);
    text-align: center;
    font-family: "Poppins";
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%; /* 52.8px */
    margin-bottom: 120px;
}

.social-audit-header-text.narrow {
    font-size: 38px;
    font-style: normal;
    font-weight: 700;
    line-height: 108%; /* 41.04px */
    margin-bottom: 40px;
}

.social-audit-container {
    margin-top: 100px;
}

.social-audit-container.narrow {
    margin-top: 50px;
}

.social-audit-container.narrow .social-top-2 .main-content {
    margin-top: 40px;
}

.social-audit-summary-panel {
    background-color: #F5F7F7;
}

.social-audit-summary-panel.top {
    margin-top: 80px !important;
    padding: 60px 120px !important;
}

.social-audit-summary-panel.left {
    margin-top: 80px !important;
    padding: 30px 10px !important;
}

.social-audit-summary-panel.top .layout-grid {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: auto auto;
    column-gap: 70px;
    row-gap: 70px;
    margin-top: 60px;
}

.social-audit-summary-panel.left .layout-grid {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: 40px;
}

.social-audit-summary-panel .title, .social-audit-advert-container .title {
    text-align: center;
    width: 100%;
    justify-content: center;
    display: flex;
}

.social-audit-summary-panel.top .title h3, .social-audit-advert-container.top .title h3 {
    font-family: Poppins;
    font-size: 38px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%; /* 41.8px */
    max-width: 710px;
    align-self: center;
}

.social-audit-summary-panel.left .title h3, .social-audit-advert-container.left .title h3 {
    font-family: Poppins;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%; /* 30.8px */
    align-self: center;
}

.social-audit-summary-panel .sub-title, .social-audit-advert-container .sub-title>span {
    text-align: center;
    width: 100%;
    margin-top: 10px;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 20.8px */
    letter-spacing: -0.32px;
}

.social-audit-advert-container .sub-title>span {
    max-width: 710px;
}

.social-audit-advert-container.top {
    margin-top: 80px !important;
}

.social-audit-advert-container.left {
    margin-top: 40px !important;
}

.social-audit-advert-container.top .social-audit-advert {
    margin-top: 70px !important;
}

.social-audit-advert-container.left .social-audit-advert {
    margin-top: 28px !important;
}

.social-audit-advert-container .sub-title { 
    text-align: center;
    width: 100%;
    justify-content: center;
    display: flex;
}

.social-audit-advert .standard .pricing {
    color: white;
    /* Infiniscape/H1 */
    font-family: Poppins;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 108%; /* 51.84px */
}

.social-audit-advert .standard {
    background-color: #2A2735;
    color: #888;
    /* Infiniscape/Body */
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 20.8px */
    letter-spacing: -0.32px;
}

.social-audit-advert .standard h4 {
    color: #F5F7F7;
    /* Infiniscape/H5 */
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%; /* 19.8px */
}

.social-audit-advert .social-audit-signup button {
    width: 100%;
}

.social-top-2 .social-audit-signup button {
    min-width: 200px;
    margin-top: 20px;
}

.social-top-2 .social-audit-signup.narrow button {
    width: 100%;
}

.social-audit-advert .high-volume {
    background-color: #F5F7F7;
    color: #2A2735;
    /* Infiniscape/Body */
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 20.8px */
    letter-spacing: -0.32px;
}

.social-audit-advert .high-volume h4 {
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%; /* 19.8px */
}

.social-audit-advert button.btn-outline-secondary {
    border: 2px solid #2A2735;
    width: 100%;
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    min-height: 54px;
}

.social-audit-advert .high-volume .lets-talk {
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 108%; /* 43.2px */
}

.social-audit-footer {
    background-color: #2A2735;
    color: white;
    font-family: Poppins;
    font-size: 58px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%; /* 63.8px */
    padding: 140px 100px;
    text-align: center;
}

.social-audit-footer.narrow {
    font-size: 24px;
    padding: 70px 20px;
}

.social-audit-footer button {
    margin-top: 20px;
}

.social-audit-footer.narrow button {
    margin-top: 20px;
}
