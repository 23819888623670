.topSect {
    padding-left: 86px;
    padding-right: 100px;
    padding-bottom: 10px;
    bottom: 5px;
    align-content: end;
}

.landing-main {
    margin-top: 30px !important;
}

.landing-main.mobile h1 {
    font-family: Poppins;
    font-size: 38px;
    font-style: normal;
    font-weight: 700;
    line-height: 108%; /* 62.64px */
    text-align: center;
}

.landing-main h1 {
    font-family: Poppins;
    font-size: 58px;
    font-style: normal;
    font-weight: 700;
    line-height: 108%; /* 62.64px */
}

.landing-main p {
    color: #2A2735;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
}

.landing-main.mobile p {
    text-align: center;
}

.divider-gradient {
    display: flex;
    padding: 140px 100px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    width: 100%;
    height: 536;
    background: var(--Grad-1, linear-gradient(180deg, #D82A93 5.95%, #692A93 95.26%));
    color: white;
    font-size: 58px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%;
    text-align: center;
    margin-top: 80px;
    position: relative;
}

.divider-gradient.mobile {
    font-size: 33px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%; /* 36.3px */
    padding: 70px 20px;
    margin-top: 40px;
}

.colorPanelText {
    color: #2A2735;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
    letter-spacing: -0.28px;
}

.grayPanel.narrow {
    padding: 40px 10px;
    font-family: Poppins;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%; /* 30.8px */
    text-align: center;
}

.grayPanel {
    background-color: #F5F7F7;
    padding: 80px 120px;
    color: #2A2735;
    font-family: Poppins;
    font-size: 38px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%; 
    margin-top: 40px;
    margin-bottom: 40px;
}

.kyndr-advert {
    background-color: purple;
    background-image: url('../images/kyndr-background.svg');
    background-size: cover;
    padding: 70px 30px !important;
    border-radius: 20px;
    color: white;
}

.kyndr-advert.narrow {
    padding: 40px 20px !important;
    text-align: center;
}

.kyndr-advert.narrow .main-image {
    margin-bottom: 32px;
    padding-left: 10px;
    padding-right: 10px;
}

.kyndr-advert .title1 {
    font-family: "Helvetica Neue";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: 0.1px;
    opacity: 0.5;
    margin-bottom: 20px;
    align-self: start
}

.kyndr-advert.narrow .title1 {
    align-self: center;
}

.kyndr-advert .title2 {
    font-family: Poppins;
    font-size: 38px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%; 
    align-items: start;
    margin-bottom: 20px;
}

.kyndr-advert.narrow .title2 {
    font-size: 28px;
}

.kyndr-advert .body {
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; 
    letter-spacing: -0.28px;
    align-items: start;
    margin-bottom: 32px;
}

.kyndr-advert .learn-more {
    margin-top: 40px; 
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
}

.kyndr-advert.narrow .learn-more {
    justify-content: center;
}

.kyndr-advert .learn-more a {
    text-decoration: none;
    color: white;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.kyndr-advert .learn-more a:hover, .kyndr-advert .learn-more a:active {
    color: lightgray;
}


.default-card .app-store-icons {
    padding-top: 26px;
    justify-content: center;
    display: flex;
    width: 100%;
}

.advert-header h3 {
    margin-top: 50px;
    margin-bottom: 50px;
    text-align: center;
    font-family: Poppins;
    font-size: 38px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%; /* 41.8px */
}

.advert-header.narrow h3 {
    font-size: 28px;
    margin-bottom: 28px;
}

.landing-sa {
    margin-top: 60px !important;
    margin-bottom: 78px !important;
}

.landing-sa.mobile {
    margin-bottom: 50px !important;
}

.landing-sa.mobile a {
    text-align: center;
}

.landing-sa.mobile a button {
    width: 80%;
}

.landing-sa p {
    text-align: center;
    /* Infiniscape/Body */
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 20.8px */
    letter-spacing: -0.32px;
}

.row.panel-grid>* {
    margin-top: 10px;
}

div.sign-up-learn-more {
    display: flex;
    flex-direction: row; 
    flex: 1;
    justify-content: center; 
    align-items: center;
    gap: 24px; 
    margin-top: 40px;
    margin-bottom: 20px;
}

div.sign-up-learn-more.mobile {
    flex-direction: column;
}

div.sign-up-learn-more.narrow {
    align-items: stretch;
}

div.sign-up-learn-more.narrow a button {
    width: 80%;
}