/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

a.primary-link, button.primary-link {
    color: #fc32ac !important;
    text-align: center;
    font-family: "Poppins";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration: none;
}

a.primary-link:active, a.primary-link:hover {
    color: black;
}

/* Button styling */
.btn {
    min-height: 44px;
    min-width: 44px;
    border-radius: 80px !important;
    text-align: center;
    font-family: "Poppins";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-left: 30px;
    padding-right: 30px;
}

.btn.btn-primary:hover, .btn.btn-secondary:hover {
    background-color: #fc32ac !important;
    color: #fff;
}

.btn.btn-primary:active, .btn.btn-secondary:active {
    background-color: #fc32ac;
}

.btn.btn-primary {
    background-color: #d72a92 !important;
    color: white;
    border: none;
    min-height: 54px;
    padding-left: 30px !important;
    padding-right: 30px !important;
}

.btn.disabled, .btn[disabled] {
    opacity: 1.0;
}

.btn-busy {
    min-width: 100px;
}

.full-width {
    width: 100%;
}

.flex {
    display: flex;
    gap: 5px;
}

.flex .btn {
    flex: 1;
}

input.form-check-input {
    accent-color: #d72a92;
    margin-right: 5px;
}

/* Layout */
.row {
    padding: 5px;
}

.layout-body {
    padding-bottom: 20px;
}

.title-color {
    color: #EB0196;
}

/* Default */
.application {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
}

/* Fonts */
.poppins-thin {
    font-family: "Poppins", sans-serif;
    font-weight: 100;
    font-style: normal;
}

.poppins-extralight {
    font-family: "Poppins", sans-serif;
    font-weight: 200;
    font-style: normal;
}

.poppins-light {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-style: normal;
}

.poppins-regular {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.poppins-medium {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-style: normal;
}

.poppins-semibold {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-style: normal;
}

.poppins-bold {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-style: normal;
}

.poppins-extrabold {
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    font-style: normal;
}

.poppins-black {
    font-family: "Poppins", sans-serif;
    font-weight: 900;
    font-style: normal;
}

.poppins-thin-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 100;
    font-style: italic;
}

.poppins-extralight-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 200;
    font-style: italic;
}

.poppins-light-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-style: italic;
}

.poppins-regular-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: italic;
}

.poppins-medium-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-style: italic;
}

.poppins-semibold-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-style: italic;
}

.poppins-bold-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-style: italic;
}

.poppins-extrabold-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    font-style: italic;
}

.poppins-black-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 900;
    font-style: italic;
}
/* End Fonts */

/* Padding */
.pt5 {
    padding-top: 5px;
}

.pt15 {
    padding-top: 15px;
}

.pb15 {
    padding-bottom: 15px;
}

.pb5 {
    padding-bottom: 5px;
}

/* Margins */
.mh-4 {
    margin-left: 4px;
    margin-right: 4px;
}

/* Font size */
.font-18 {
    font-size: 18px;
}

/* Social Audit */
.socialAuditPlaceholder {
    width: 60px;
    height: 60px;
    background-color: transparent;
    display: inline-block;
}

/* bullet points */
ul.no-bullets {
    list-style-type: none;
}

.scrolllink {
    cursor: pointer;
}

/* Headings */
h4 {
    font-family: "Poppins", sans-serif;
    font-weight: 600 !important;
    font-style: normal;
    font-size: 21;
}

.loginLinkFooter {
    margin-bottom: 35px;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 20.8px */
    letter-spacing: -0.32px;
}

/* For default cards */
.default-card-row {
    margin-bottom: 120px;
}

.default-card-row.narrow {
    margin-bottom: 70px;
}

.row.default-card-row>* {
    margin-bottom: 30px;
}

.default-card {
    border-radius: 20px !important;
    padding: 70px 49px !important;
    background: var(--White, #F5F7F7);
    height: 100% !important;
    box-shadow: 0px 69px 19px 0px rgba(0, 0, 0, 0.00), 0px 44px 18px 0px rgba(0, 0, 0, 0.01), 0px 25px 15px 0px rgba(0, 0, 0, 0.05), 0px 11px 11px 0px rgba(0, 0, 0, 0.09), 0px 3px 6px 0px rgba(0, 0, 0, 0.10);
}

.default-card.narrow {
    padding: 30px 20px !important;
}

.default-card .card-title {
    color: #09080D;
    font-family: Poppins;
    font-size: 21px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%; /* 23.1px */
    text-align: center;
}

.default-card.narrow .card-title {
    font-size: 20px;
}

.default-card .card-img-top {
    margin-bottom: 30px;
}

.default-card .card-text {
    color: var(--Infiniscape-MIdnight-Breeze, #2A2735);
    text-align: center;
    /* Infiniscape/Body */
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 20.8px */
    letter-spacing: -0.32px;
}
