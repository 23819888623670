.registerModal>.modal-content {
    border-radius: 10px;
}

.registerModal .modal-header {
    border: 0;
    padding-bottom: 0px;
}

.registerModal .modal-header>.btn-close {
    background-size: 10px;
}

.registerModal .loginSubmit {
    width: 100%;
}

.registerModal .loginLinkFooter {
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 20.8px */
    letter-spacing: -0.32px;
}

