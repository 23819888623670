.loginForm {
    color: #333;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
}

.loginForm label {
    margin-bottom: 4px;
}

.loginForm input {
    height: 48px;
    border-radius: 0;
}

.loginForm > div {
    margin-bottom: 8px !important;
}

.loginSubmit {
    margin-top: 40px;
    margin-bottom: 35px;
}

a.primary-link.login-link, button.primary-link.login-link {
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    letter-spacing: -0.32px;
}

.loginForm input::placeholder {
    color: #999;
    font-weight: 300;
}

button.primary-link.login-link {
    padding-left: 0px;
}