.footer {
    background-color: #24145C;
    color: white;
    padding: 20px;
}

.footer-list {
    flex: 1;
    flex-direction: column;
}

.footer-list a {
    color: white;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-style: normal;
    text-decoration: none;
}

.footer-logo-text {
    font-size: 42px;
    margin-bottom: 15px;
}
