a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }

  .default-top-menu {
    margin-left: 30px;
    margin-right: 30px;
  }
}

@media (max-width: 767px) { 
  .default-top-menu {
    margin-left: 0;
    margin-right: 0;
  }
}

.default-top-menu {
  border-radius: 80px;
  
  background-color: #F5F7F7;
  padding-left: 10px;
  padding-right: 10px;
}

li.dropdownMenu a.dropdown-toggle.nav-link{
  color: black;
  padding-left: 48px;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%; 
}